import { useState } from "react";
import { Link, useOutletContext } from "react-router-dom";
import { useSelector } from "react-redux";
import { Search } from "@mui/icons-material";
import {
  Box,
  Card,
  LinearProgress,
  CardContent,
  Container,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  Link as MaterialLink,
} from "@mui/material";

import FlexWrapper from "../../../components/FlexWrapper";
import PackProcessViewDeleteOrderModal from "../../../components/multi-page/pack-process-view/PackProcessViewDeleteOrderModal";
import Loading from "../../../components/Loading";

const PackProcessViewOrdersPage = () => {
  const { orders, setOrders } = useOutletContext();
  const { rolelevel } = useSelector((state) => state.user);

  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState("");

  if (!orders || !orders.length) {
    return (
      <Typography textAlign="center" variant="h4" pt={2}>
        No Orders Being Packed
      </Typography>
    );
  }

  if (loading) return <Loading message={loading} />;

  const filteredOrders = search
    ? orders.filter(
        (order) =>
          order.createdfrom.includes(search) ||
          order.createdby.toUpperCase().includes(search)
      )
    : orders;

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" textAlign="center" gutterBottom>
        Pack Orders In Progress
      </Typography>

      <Grid container alignItems="center">
        <Grid item xs={12} md={6} pb={1}>
          <TextField
            fullWidth
            size="small"
            label="Search"
            value={search}
            onChange={(event) => setSearch(event.target.value.toUpperCase())}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} pb={1}>
          <Typography textAlign="right">Total: {orders?.length}</Typography>
        </Grid>
      </Grid>

      <Box sx={{ height: "75vh", overflow: "scroll", py: 1 }}>
        <Stack spacing={2} flexWrap="wrap" alignItems="center">
          {filteredOrders.length ? (
            filteredOrders.map((order) => (
              <Card variant="outlined" key={order._id} sx={{ width: "100%" }}>
                <CardContent>
                  <FlexWrapper
                    justifyContent="space-between"
                    alignItems="baseline"
                  >
                    <Box>
                      {rolelevel === 0 ? (
                        <FlexWrapper gap={1} alignItems="baseline">
                          <MaterialLink
                            component={Link}
                            to={`/portal/warehouse/pack-process-v2/${order._id}`}
                            target="_blank"
                          >
                            <Typography variant="caption">
                              {order._id}
                            </Typography>
                          </MaterialLink>
                          {order.totalpacked === 0 ? (
                            <PackProcessViewDeleteOrderModal
                              order={order}
                              setLoading={setLoading}
                              setOrders={setOrders}
                            />
                          ) : null}
                        </FlexWrapper>
                      ) : null}
                    </Box>
                    <Box>
                      <Typography
                        variant="h6"
                        gutterBottom
                        textAlign="center"
                        fontWeight="bold"
                      >
                        <MaterialLink
                          href={`https://${process.env.REACT_APP_NS_ACCOUNT_KEY}.app.netsuite.com/app/accounting/transactions/transaction.nl?id=${order.createdfrominternalid}`}
                          target="_blank"
                        >
                          {order.createdfrom} - {order.brand}
                        </MaterialLink>
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        gutterBottom
                        textAlign="center"
                      >
                        {order.customer}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        gutterBottom
                        textAlign="center"
                      >
                        PO#: {order.ponumber}
                      </Typography>
                    </Box>

                    <Typography
                      variant="caption"
                      gutterBottom
                      textAlign="right"
                    >
                      {order.status?.toUpperCase() === "OPEN" && "IN PROGRESS"}
                    </Typography>
                  </FlexWrapper>

                  <Typography variant="body1" color="textSecondary">
                    <strong>Started By:</strong> {order.createdby}
                  </Typography>
                  <Typography variant="body1" color="textSecondary">
                    <strong>Total Quantity to Pack:</strong>{" "}
                    {order.totalitemstopack}
                  </Typography>
                  <Typography variant="body1" color="textSecondary">
                    <strong>Total Quantity Packed:</strong> {order.totalpacked}
                  </Typography>
                  <Typography variant="body1" color="textSecondary">
                    <strong>Remaining Quantity To Pack:</strong>{" "}
                    {order.totalitemstopack - order.totalpacked}
                  </Typography>
                  <Typography variant="body1" color="textSecondary">
                    <strong>Created At:</strong>{" "}
                    {new Date(order.createdAt)?.toLocaleString()}
                  </Typography>
                  <Typography variant="body1" color="textSecondary">
                    <strong>Last Update:</strong>{" "}
                    {new Date(order.updatedAt)?.toLocaleString()}
                  </Typography>

                  <Box mt={2}>
                    <FlexWrapper alignItems="center" flexWrap="nowrap">
                      <Box mr={2} sx={{ width: "100%" }}>
                        <LinearProgress
                          variant="determinate"
                          value={parseInt(
                            (
                              (order.totalpacked / order.totalitemstopack) *
                              100
                            ).toFixed(2)
                          )}
                          color="success"
                        />
                      </Box>

                      <Typography variant="body2" color="GrayText">{`${parseInt(
                        (
                          (order.totalpacked / order.totalitemstopack) *
                          100
                        ).toFixed(2)
                      )}%`}</Typography>
                    </FlexWrapper>
                  </Box>
                </CardContent>
                {/* <CardActions>
                <FlexWrapper
                  justifyContent="flex-end"
                  width="100%"
                  alignItems="center"
                >
                  <Link to={`${order._id}`}>
                    <MaterialLink component="button" variant="button">
                      View Order Details{" "}
                      <span>
                        <Icon>
                          <ArrowForward fontSize="small" />
                        </Icon>
                      </span>
                    </MaterialLink>
                  </Link>
                </FlexWrapper>
              </CardActions> */}
              </Card>
            ))
          ) : (
            <Typography textAlign="center" variant="h6" pt={2}>
              No Orders Being Packed
            </Typography>
          )}
        </Stack>
      </Box>
    </Container>
  );
};
export default PackProcessViewOrdersPage;
