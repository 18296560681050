export const READYTOSHIPCOLUMNS = [
  {
    field: "Date",
    headerName: "Date",
    type: "date",
    width: 100,
    align: "center",
    headerAlign: "center",
    valueGetter: (params) => new Date(params.value),
  },
  {
    field: "Document Number",
    headerName: "SO #",
    type: "string",
    width: 100,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "Warehouse Order Status",
    headerName: "Warehouse Order Status",
    type: "string",
    width: 200,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "Customer",
    headerName: "Customer",
    type: "string",
    width: 150,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "PO #",
    headerName: "PO #",
    type: "string",
    width: 200,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "Warehouse Notes",
    headerName: "Warehouse Notes",
    type: "string",
    width: 200,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "Shipping Method",
    headerName: "Shipping Method",
    type: "string",
    width: 150,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "Must Ship With",
    headerName: "Must Ship With",
    type: "string",
    width: 150,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "Date Ready to Ship",
    headerName: "Date Ready to Ship",
    type: "date",
    width: 150,
    align: "center",
    headerAlign: "center",
    valueGetter: (params) => (params.value ? new Date(params.value) : null),
  },
  {
    field: "Location",
    headerName: "Location",
    type: "string",
    width: 150,
    align: "center",
    headerAlign: "center",
  },
];
