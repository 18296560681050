import { scaleZPL } from "./scaleZPL";
import axios from "../../axios/axios.config";

export const printCustomerLabel = async ({
  type = "print",
  currentPrinter,
  title = "",
  data,
}) => {
  try {
    if (!currentPrinter) {
      throw new Error(
        "No printer selected. Please select a printer in your settings."
      );
    }

    let template = null;
    const {
      ucclabeltemplate,
      ponumber,
      specialhandlingnotes,
      packdetails,
      createdfrom,
      status,
    } = data;

    if (status !== "packed") {
      throw new Error("Error: Order must be completely packed before printing");
    }

    if (ucclabeltemplate === "AUTOZONE") {
      template = `^XA
^MMT
^PW1200^LL1800
^LS0^PON^LH0,0
^FT700,100^FB1800,2,0,L,0^A0R,225^FDPO# ${ponumber}^FS
^FT200,100^FB1600,5,0,L,0^A0R,125^FD${specialhandlingnotes}^FS
^FT60,850^FB500,1,0,L,0^A0R,75^FDBOX / PLT:^FS
^FT60,1200^FB250,1,0,L,0^A0R,75^FD^SN01,1,N^FS
^FT60,1350^FB250,1,0,L,0^A0R,75^FDof  ${packdetails?.length}^FS
^PQ${packdetails?.length},0,1,Y
^XZ`;
    }

    if (ucclabeltemplate === "NISSAN") {
      template = "";
      for (const pallet of packdetails) {
        template += `^XA
^MMT^PW1200^LL1800
^LS0
^PON
^LH0,0
^FT75,1790^A0B,50^FDPART NO^FS
^FT125,1790^A0B,50^FD(P)^FS
^FT175,1400^A0B,100^FD${
          pallet?.items?.length > 1
            ? "MIXED LOAD LABEL"
            : `${pallet?.items[0]?.item?.item}`
        }^FS
^FO215,0^GB6,1850,6^FS
^FT300,1790^A0B,50^FDQUANTITY^FS
^FT350,1790^A0B,50^FD(Q)^FS
^FT450,1400^A0B,100^FD${pallet?.totalitemquantity}^FS
^FO220,900^GB330,6,6^FS
^FT400,875^FB600,4,0,L,0^A0B,44^FDAIT\\&550 HInton Oaks Blvd\\&Knightdale, NC 27545^FS
^FT640,875^FB600,5,0,L,0^A0B,44^FD${new Date()?.toLocaleDateString()}^FS
^FT700,875^FB600,5,0,L,0^A0B,44^FDPallet ${pallet?.palletId}  of  ${
          packdetails?.length
        }^FS
^FO550,625^GB6,1200,6^FS
^FT625,1790^A0B,50^FDSUPPLIER^FS
^FT675,1790^A0B,50^FD(V)^FS
^FT675,1500^A0B,75^FDF01972002^FS
^FT825,1500^BY3,3,133^B3B,N,125,N,N^FDVF01972002^FS
^FO875,625^GB6,1200,6^FS
^FT950,1790^A0B,50^FDSERIAL^FS
^FT1000,1790^A0B,50^FD(S)^FS
^FT1000,1550^A0B,75^FD${pallet?.palletucc}^FS
^FT1150,1575^BY3,3,133^B3B,N,125,N,N^FDS${pallet?.palletucc}^FS
^FO550,620^GB660,6,6^FS
^PQ1,0,1,Y
^XZ`;
      }
    }

    if (!template) {
      throw new Error(
        `No template exists for ${ucclabeltemplate}-${createdfrom}`
      );
    }

    //either print or return the final zpl code generated generated
    if (type === "print") {
      let templateType =
        currentPrinter.printerresolution !== "300dpi"
          ? scaleZPL(template)
          : template;
      let printJobUrl = "https://api.printnode.com/printjobs";
      let apiID = process.env.REACT_APP_PRINT_API_ID;
      let body = {
        printerId: currentPrinter.printnodeid,
        title,
        contentType: "raw_base64",
        content: btoa(templateType),
        source: `MERN > Print Order Labels > printCustomerLabelFn`,
      };

      await axios.post(printJobUrl, body, {
        headers: {
          Authorization: "Basic " + btoa(apiID),
          "Content-Type": "application/json",
        },
      });
    } else {
      //returns final zpl code
      return template;
    }
  } catch (error) {
    throw error;
  }
};
