import { handleBrowserPrintToPrinter } from "./browserPrint";
import { scaleZPL } from "./scaleZPL";

import axios from "../../axios/axios.config";

export const printPickOrderPalletLabel = async ({
  currentPrinter,
  tranid,
  username,
  brand,
  shippingAddress,
  poNumber,
  shipMethod,
  warehouseNotes,
  alertFn,
  title,
  currentLocationInternalId,
  pickQty,
  relatedtransferorders,
  mustshipwith = "",
  trandate = "",
  type = "print",
}) => {
  try {
    if (!currentPrinter) {
      throw new Error(
        "No Printer is assigned. Please select a printer in your settings."
      );
    }

    const locationAddressResponse = await axios.get(
      `locations/get/location-address?locationinternalid=${currentLocationInternalId}`
    );

    if (!locationAddressResponse?.data?.address) {
      throw new Error("No Location Address Found.");
    }

    alertFn({
      type: "success",
      message: "Printing Pick Label...",
      duration: 2000,
    });

    const template = `^XA^MMT^PW1200^LL1800^LS0^POI^LH0,0
^FT105,150^BY4,3,133^BCN,,N,N^FD${
      tranid?.includes(":") ? tranid?.split(":")[0] : tranid
    }^FS
^FT100,240^A0N,111,126^FD${tranid}^FS
^FT500,75^FB591,1,0,R,0^A0N,44^FD${username}^FS
^FT720,250^FB400,1,0,R,0^A0N,44^FD${trandate}^FS
^FT650,475^FB500,1,0,C,0^A0N,250^FD${
      tranid?.includes(":")
        ? tranid?.split(":")[0]?.slice(-4)
        : tranid?.slice(-4)
    }^FS
^FT675,625^FB500,1,0,C,0^A0N,175^FD${brand}^FS
^FO50,266^GB1100,375,3^FS
^FT75,330^A0N,44^FDFROM/DE:^FS
^FT75,575^FB1100,5,0,L,0^A0N,44^FD${locationAddressResponse?.data?.address?.replace(
      /\n/g,
      "\\&"
    )}^FS
^FO50,638^GB1100,375,3^FS
^FT720,720^A0N,44^FDShip With: ${mustshipwith}^FS
^FT75,720^A0N,44^FDTO/A:^FS
^FT75,975^FB1100,5,0,L,0^A0N,44^FD${shippingAddress?.replace(/\r\n/g, "\\&")}^FS
^FT60,1080^FB1100,1,0,L,0^A0N,44^FDPO #: ${poNumber}^FS
^FT60,1150^FB1100,1,0,L,0^A0N,44^FDShipping Method: ${shipMethod}^FS
^FT60,1220^FB1100,1,0,L,0^A0N,44^FDProjected Pick Qty: ${pickQty}^FS
^FT60,1340^FB1100,2,0,L,0^A0N,44^FDRelated Transfer Orders: ${relatedtransferorders}^FS
^FT60,1600^FB1100,5,0,L,0^A0N,44^FDWarehouse Notes: ${warehouseNotes}^FS
^FT60,1650^A0N,65^FDPALLET #:__________  of __________^FS
^FT60,1760^A0N,65^FDWEIGHT:________________________^FS
^PQ1,0,1,Y^XZ`;

    if (type === "print") {
      //print out code
      let templateType =
        currentPrinter.printerresolution !== "300dpi"
          ? btoa(scaleZPL(template)) // scales to 203dpi
          : btoa(template);

      let printJobUrl = "https://api.printnode.com/printjobs";
      let apiID = process.env.REACT_APP_PRINT_API_ID;
      let body = {
        printerId: currentPrinter.printnodeid,
        title,
        contentType: "raw_base64",
        content: templateType,
        source: "MERN > Pick Order > printPalletLabel",
      };

      if (
        currentPrinter.printername?.startsWith("USB") &&
        currentPrinter.printnodeid === 0
      ) {
        return handleBrowserPrintToPrinter({
          currentPrinter,
          template,
          alertFn,
        });
      } else {
        return axios.post(printJobUrl, body, {
          headers: {
            Authorization: "Basic " + btoa(apiID),
            "Content-Type": "application/json",
          },
        });
      }
    } else {
      //if type is not print then return zpl code
      return template;
    }
  } catch (error) {
    alertFn({
      type: "error",
      message: `Could not print pick label: ${error.message}`,
      duration: 5000,
    });
  }
};

export const printMultiPickOrderPalletLabelsWithOneTemplate = async ({
  currentPrinter,
  template,
}) => {
  try {
    if (!currentPrinter) {
      throw new Error(
        "No Printer is assigned. Please select a printer in your settings."
      );
    }
    //adjust template type based on printer resolution
    let templateType =
      currentPrinter.printerresolution !== "300dpi"
        ? btoa(scaleZPL(template)) // scales to 203dpi
        : btoa(template);

    if (
      currentPrinter.printername?.startsWith("USB") &&
      currentPrinter.printnodeid === 0
    ) {
      await handleBrowserPrintToPrinter({
        currentPrinter,
        template,
        alertFn: null,
      });
    } else {
      let printJobUrl = "https://api.printnode.com/printjobs";
      let apiID = process.env.REACT_APP_PRINT_API_ID;
      let body = {
        printerId: currentPrinter.printnodeid,
        title: "Print Pick Order Labels",
        contentType: "raw_base64",
        content: templateType,
        source:
          "MERN > Stock Orders > printMultiPickOrderPalletLabelsWithOneTemplate",
      };

      await axios.post(printJobUrl, body, {
        headers: {
          Authorization: "Basic " + btoa(apiID),
          "Content-Type": "application/json",
        },
      });
    }
  } catch (error) {
    throw error;
  }
};
