import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useOutletContext } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { ArrowBack, Search } from "@mui/icons-material";
import {
  Button,
  Container,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import axios from "../../../../axios/axios.config";
import Loading from "../../../../components/Loading";
import FlexWrapper from "../../../../components/FlexWrapper";

const StockLocatorBinLookupPage = () => {
  const [bin, setBin] = useState("");
  const [productData, setProductData] = useState([]);
  const [loading, setLoading] = useState("");
  const [error, setError] = useState("");

  const navigate = useNavigate();
  const { getMovesAndPickInProgress, currentNetsuiteLocationIds } =
    useOutletContext();
  const handleProductSubmit = async (event) => {
    event.preventDefault();
    setLoading("Loading Inventory Data...");

    if (error) {
      setError("");
    }

    try {
      const updatedData = [];
      const inventoryResponse = await axios.get(
        `netsuite/stock-locator/get/inventory?type=bin&identifier=${bin}&nsLocations=${currentNetsuiteLocationIds.join(
          ","
        )}`
      );

      for (const inventoryData of inventoryResponse.data) {
        updatedData.push({
          ...inventoryData,
          available: await getMovesAndPickInProgress(inventoryData),
        });
      }

      setProductData(updatedData);
    } catch (error) {
      setError(error?.response?.data?.msg || error.message);
    } finally {
      setLoading("");
    }
  };

  const handleReset = () => {
    setBin("");
    setProductData([]);
    setError(null);
  };

  if (Boolean(loading)) {
    return <Loading message={loading} />;
  }

  return (
    <Container maxWidth="md">
      <FlexWrapper justifyContent="space-between" alignItems="center">
        <IconButton size="small" onClick={() => navigate(-1)} disableRipple>
          <Tooltip title="Back" arrow placement="bottom">
            <ArrowBack fontSize="small" />
          </Tooltip>
        </IconButton>

        {Boolean(productData.length) && (
          <Typography fontWeight="bold">{productData[0]?.binnumber}</Typography>
        )}

        {Boolean(productData.length) && (
          <Button size="small" variant="outlined" onClick={handleReset}>
            Reset Data
          </Button>
        )}
      </FlexWrapper>

      {!productData?.length ? (
        <form onSubmit={handleProductSubmit}>
          <Typography variant="h4" textAlign="center">
            Bin Lookup
          </Typography>
          <TextField
            sx={{ mt: 1 }}
            autoFocus
            label="Enter Bin  Number"
            fullWidth
            value={bin}
            onChange={(event) => {
              setBin(event.target.value?.toUpperCase()?.trim());
            }}
            InputProps={{
              endAdornment: (
                <IconButton type="submit">
                  <Search />
                </IconButton>
              ),
            }}
            error={Boolean(error)}
            helperText={error}
          />
        </form>
      ) : (
        <>
          <Stack py={1}>
            <FlexWrapper justifyContent="space-between">
              <Typography variant="body2">
                <strong>Bin Status: </strong>
                {productData[0]?.binstatus}
              </Typography>
              <Typography variant="body2">
                <strong>Location: </strong>
                {productData[0]?.binlocation}
              </Typography>
            </FlexWrapper>
            <FlexWrapper justifyContent="space-between">
              <Typography variant="body2">
                <strong>Bin Type: </strong>
                {productData[0]?.bintype}
              </Typography>
              <Typography variant="body2">
                <strong>Brand: </strong>
                {productData[0]?.binbrand}
              </Typography>
            </FlexWrapper>
            <FlexWrapper justifyContent="space-between">
              <Typography variant="body2">
                <strong>Bin Size: </strong>
                {productData[0]?.binsize}
              </Typography>
              <Typography variant="body2">
                <strong>Bin Box Size: </strong>
                {productData[0]?.binboxsizetype}
              </Typography>
            </FlexWrapper>
          </Stack>

          <DataGrid
            sx={{ height: "65vh", mt: 1 }}
            columns={[
              {
                field: "itemname",
                headerName: "Item",
                type: "string",
                flex: 1,
                align: "center",
                headerAlign: "center",
              },
              {
                field: "available",
                headerName: "Available",
                type: "number",
                flex: 1,
                align: "center",
                headerAlign: "center",
              },
            ]}
            rows={productData}
            density="compact"
            getRowId={(row) => row.rownumber}
          />
        </>
      )}
    </Container>
  );
};
export default StockLocatorBinLookupPage;
