import { useState } from "react";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { resetUser } from "../redux/features/user/userSlice";
import { resetTote } from "../redux/features/tote/toteSlice";
import { removeLocalStorage } from "../utils/logout/logout";
import { departmentMap } from "../utils/menus/menu";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  Avatar,
  Tooltip,
  MenuItem,
  useMediaQuery,
} from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";
import AITLOGO from "../assets/AIT-LOGO.png";

function NavBar() {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorMenu, setAnchorMenu] = useState({
    salesForms: null,
    externalSalesForms: null,
  });
  const [anchorElUser, setAnchorElUser] = useState(null);
  const matches = useMediaQuery("(max-width:600px)");
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    username,
    currentDepartment,
    isAuthenticated,
    firstname,
    lastname,
    currentSubsidiary,
    currentLocation,
    rolelevel,
  } = useSelector((state) => state.user);
  const { isNavBarHidden } = useSelector((state) => state.nav);

  const settings = [
    { name: username, action: navigatetoUserProfile },
    { name: "FEEDBACK PORTAL", action: navigateToFeedbackPage },
    { name: "ADMIN", action: navigateToAdminPage, minRoleLevel: 0 },
    { name: `${currentSubsidiary}-${currentLocation}`, action: null },
    { name: "LOGOUT", action: handleLogout },
  ];

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  function handleCloseUserMenu() {
    setAnchorElUser(null);
  }

  function handleLogout() {
    removeLocalStorage();
    dispatch(resetUser());
    dispatch(resetTote());
    handleCloseUserMenu();
    navigate("/");
  }

  function navigatetoUserProfile() {
    navigate(`/user-profile`);
    handleCloseUserMenu();
  }

  function navigateToAdminPage() {
    navigate("/admin");
    handleCloseUserMenu();
  }
  function navigateToFeedbackPage() {
    navigate("/feedback");
    handleCloseUserMenu();
  }

  function openSubmenu(e, name) {
    setAnchorMenu({ ...anchorMenu, [name]: e.currentTarget });
  }

  function handleCloseSubmenu(name) {
    setAnchorMenu({ ...anchorMenu, [name]: null });
  }

  return (
    isAuthenticated &&
    currentDepartment &&
    !isNavBarHidden && (
      <AppBar
        position="static"
        color="transparent"
        elevation={1}
        sx={{
          display:
            location.pathname === "/" ||
            location.pathname === "/info-select" ||
            location.pathname === "/confirmation" ||
            location.pathname.startsWith("/admin") ||
            (location.pathname === "/portal/warehouse/pack-process" &&
              location.search &&
              location.search.includes("order"))
              ? "none"
              : "block",
          mb: { xs: 1, md: 3 },
        }}
      >
        <Container
          maxWidth="xl"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            overflow: "hidden",
          }}
        >
          <Link to={`/portal/${currentDepartment.toLowerCase()}`}>
            <Box
              sx={{
                width: { xs: "200px", md: "300px" },
              }}
            >
              <img width="100%" height="50px" src={AITLOGO} alt="AIT" />
            </Box>
          </Link>

          <Toolbar disableGutters>
            {matches ? (
              <Box
                sx={{
                  display: { xs: "flex", md: "none" },
                }}
              >
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: "block", md: "none" },
                  }}
                >
                  {currentDepartment &&
                    departmentMap[currentDepartment].map((page) =>
                      page.nested ? (
                        !page.admin || (page.admin && page.admin(rolelevel)) ? (
                          <div key={page.title}>
                            <MenuItem
                              onClick={(e) => openSubmenu(e, page.nestedName)}
                            >
                              <Typography variant="button">
                                {page.title}
                              </Typography>
                            </MenuItem>
                            <Menu
                              id="menu-appbar"
                              anchorEl={anchorMenu[page.nestedName]}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                              }}
                              keepMounted
                              transformOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              open={Boolean(anchorMenu[page.nestedName])}
                              onClose={() =>
                                handleCloseSubmenu(page.nestedName)
                              }
                            >
                              {page.submenus.map((submenu) => {
                                if (
                                  !submenu?.admin ||
                                  submenu?.admin(rolelevel)
                                ) {
                                  return (
                                    <Link
                                      to={submenu.to}
                                      target={submenu.target}
                                      key={submenu.title}
                                      style={{
                                        color: "inherit",
                                        textDecoration: "none",
                                      }}
                                    >
                                      <MenuItem
                                        onClick={() => {
                                          handleCloseSubmenu(page.nestedName);
                                          handleCloseNavMenu();
                                        }}
                                      >
                                        <Typography variant="button">
                                          {submenu.title}
                                        </Typography>
                                      </MenuItem>
                                    </Link>
                                  );
                                }
                              })}
                            </Menu>
                          </div>
                        ) : null
                      ) : (
                        (!page.admin ||
                          (page.admin && page.admin(rolelevel))) && (
                          <Link
                            to={page.to}
                            key={page.title}
                            style={{
                              color: "inherit",
                              textDecoration: "none",
                            }}
                          >
                            <MenuItem onClick={handleCloseNavMenu}>
                              <Typography textAlign="center" variant="button">
                                {page.title}
                              </Typography>
                            </MenuItem>
                          </Link>
                        )
                      )
                    )}
                </Menu>
              </Box>
            ) : (
              // Desktop Styles
              <Box
                sx={{
                  display: { xs: "none", md: "flex", alignItems: "center" },
                }}
              >
                {currentDepartment &&
                  departmentMap[currentDepartment].map((page) => {
                    return page.nested ? (
                      !page.admin || (page.admin && page.admin(rolelevel)) ? (
                        <div key={page.title}>
                          <MenuItem
                            onClick={(e) => openSubmenu(e, page.nestedName)}
                          >
                            <Typography variant="button">
                              {page.title}
                            </Typography>
                          </MenuItem>
                          <Menu
                            id="menu-appbar"
                            anchorEl={anchorMenu[page.nestedName]}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                            }}
                            keepMounted
                            transformOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            open={Boolean(anchorMenu[page.nestedName])}
                            onClose={() => handleCloseSubmenu(page.nestedName)}
                          >
                            {page.submenus.map((submenu) => {
                              if (
                                !submenu?.admin ||
                                submenu?.admin(rolelevel)
                              ) {
                                return (
                                  <Link
                                    to={submenu.to}
                                    target={submenu.target}
                                    key={submenu.title}
                                    style={{
                                      color: "inherit",
                                      textDecoration: "none",
                                    }}
                                  >
                                    <MenuItem
                                      onClick={() => {
                                        handleCloseSubmenu(page.nestedName);
                                        handleCloseNavMenu();
                                      }}
                                    >
                                      <Typography variant="button">
                                        {submenu.title}
                                      </Typography>
                                    </MenuItem>
                                  </Link>
                                );
                              }
                            })}
                          </Menu>
                        </div>
                      ) : null
                    ) : (
                      (!page.admin ||
                        (page.admin && page.admin(rolelevel))) && (
                        <Link
                          to={page.to}
                          key={page.title}
                          style={{
                            color: "inherit",
                            textDecoration: "none",
                          }}
                        >
                          <MenuItem
                            sx={{ px: 0.7 }}
                            onClick={handleCloseNavMenu}
                          >
                            <Typography variant="button">
                              {page.title}
                            </Typography>
                          </MenuItem>
                        </Link>
                      )
                    );
                  })}
              </Box>
            )}

            <Box>
              <Tooltip title="Open User Settings">
                <IconButton sx={{ p: 0 }} onClick={handleOpenUserMenu}>
                  <Avatar alt="User Default">{`${firstname[0]}${lastname[0]}`}</Avatar>
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) =>
                  setting.minRoleLevel === undefined ||
                  rolelevel <= setting.minRoleLevel ? (
                    <MenuItem
                      key={setting.name}
                      onClick={setting.action}
                      sx={{
                        pointerEvents: setting.action ? "initial" : "none",
                      }}
                    >
                      <Typography
                        textAlign="center"
                        sx={{
                          textDecoration:
                            setting.name === username ? "underline" : "initial",
                        }}
                      >
                        {setting.name}
                      </Typography>
                    </MenuItem>
                  ) : null
                )}
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    )
  );
}
export default NavBar;
