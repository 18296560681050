import { scaleZPL } from "./scaleZPL";

export const handleBrowserPrintToPrinter = async ({
  currentPrinter,
  template,
  alertFn,
}) => {
  window.BrowserPrint?.getDefaultDevice(
    "printer",
    (device) => {
      device.send(
        currentPrinter.printerresolution !== "300dpi"
          ? scaleZPL(template)
          : template
      );
    },
    (error) => {
      if (alertFn && typeof alertFn === "function") {
        alertFn({
          type: "error",
          message: `Could not print: ${error.message}. Please check printer settings`,
          duration: 8000,
        });
      } else {
        throw error;
      }
    }
  );
};
