import { useRef, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import GeneralModalV3 from "../../../general/GeneralModalV3";
import Loading from "../../../Loading";

const PrintOrderLabelsPrintModal = ({
  openComponent,
  getOrderFn,
  printFn,
  buttonTitle,
  type = "pick",
}) => {
  const [order, setOrder] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState("");
  const [palletOptions, setPalletOptions] = useState([]);
  const [pallet, setPallet] = useState("");
  const [packData, setPackData] = useState(null);

  const inputRef = useRef(null);

  //handles input focus (hides keyboard when focused)
  const handleItemRefFocus = () => {
    if (inputRef.current !== null) {
      inputRef.current.readOnly = true;
      inputRef.current.focus();
      setTimeout(() => {
        inputRef.current.focus();
        inputRef.current.readOnly = false;
      });
    }
  };

  const handleSubmit = async (event, handleClose) => {
    event.preventDefault();
    setLoading("Printing...");
    try {
      let data = null;
      if (getOrderFn && typeof getOrderFn === "function") {
        data = await getOrderFn(order);
      }

      if (data && printFn && typeof printFn === "function") {
        //call the print function and pass in the order number
        await printFn(data);
        handleClose();
      } else {
        throw new Error("Print function is not valid");
      }
    } catch (error) {
      setError(error.respone?.data?.msg || error.message);
    } finally {
      setLoading("");
    }
  };

  const handlePackDataSubmit = async (event) => {
    event.preventDefault();
    if (error) {
      setError("");
    }
    setLoading("Loading Order...");
    try {
      let data = null;
      if (getOrderFn && typeof getOrderFn === "function") {
        data = await getOrderFn(order);
      }

      const orderData = data[0];

      const palletOptions = orderData?.packdetails.map(
        (palletObj) => palletObj.palletId
      );

      setPackData(orderData);
      setPalletOptions(palletOptions);
    } catch (error) {
      setError(error.respone?.data?.msg || error.message);
    } finally {
      setLoading("");
    }
  };

  const handlePackPrint = async (handleClose) => {
    setLoading("Printing...");
    try {
      await printFn(packData, pallet);
      handleClose();
    } catch (error) {
      setError(error.respone?.data?.msg || error.message);
    } finally {
      setLoading("");
    }
  };
  //clears state
  const handleModalClose = () => {
    setOrder("");
    setError("");
    setLoading("");
    if (type === "pack") {
      setPallet("");
      setPalletOptions([]);
      setPackData(null);
    }
  };

  return (
    <GeneralModalV3
      keepMounted={true}
      onClose={handleModalClose}
      onOpen={() => handleItemRefFocus()}
      openComponent={openComponent}
      disableClick={Boolean(loading)}
    >
      {(handleClose) =>
        Boolean(loading) ? (
          <Loading height="250px" message={loading} />
        ) : (
          <Box p={2}>
            <Typography variant="h5" gutterBottom textAlign="center" pb={1}>
              Search Order
            </Typography>
            {type !== "pack" && (
              <form onSubmit={(event) => handleSubmit(event, handleClose)}>
                <TextField
                  required
                  autoFocus
                  fullWidth
                  label="Enter Order Number"
                  value={order}
                  onChange={(event) =>
                    setOrder(event.target.value.toUpperCase().trim())
                  }
                  inputProps={{ autoComplete: "off" }}
                  inputRef={inputRef}
                  error={Boolean(error)}
                  helperText={error}
                />

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ my: 2 }}
                >
                  {buttonTitle}
                </Button>
              </form>
            )}

            {type === "pack" && (
              <form onSubmit={handlePackDataSubmit}>
                <TextField
                  disabled={packData !== null}
                  required
                  autoFocus
                  fullWidth
                  label="Enter Order Number"
                  value={order}
                  onChange={(event) =>
                    setOrder(event.target.value.toUpperCase().trim())
                  }
                  inputProps={{ autoComplete: "off" }}
                  inputRef={inputRef}
                  error={Boolean(error)}
                  helperText={error}
                />

                {palletOptions.length === 0 && !packData && (
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ my: 2 }}
                  >
                    Search Order
                  </Button>
                )}

                {palletOptions.length !== 0 && packData && (
                  <>
                    <FormControl
                      error={Boolean(error)}
                      required
                      sx={{ mt: 2 }}
                      fullWidth
                    >
                      <InputLabel>Pallet Number</InputLabel>
                      <Select
                        label="Pallet Number"
                        value={pallet}
                        onChange={(event) => setPallet(event.target.value)}
                      >
                        {palletOptions.map((pallet) => {
                          return (
                            <MenuItem key={pallet} value={pallet}>
                              {pallet}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>

                    <Button
                      disabled={!palletOptions.length || !pallet}
                      type="button"
                      fullWidth
                      variant="contained"
                      sx={{ my: 2 }}
                      onClick={() => handlePackPrint(handleClose)}
                    >
                      {buttonTitle}
                    </Button>
                  </>
                )}
              </form>
            )}
          </Box>
        )
      }
    </GeneralModalV3>
  );
};
export default PrintOrderLabelsPrintModal;
