import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useGeneralAlert } from "../../../../hooks/useGeneralAlert";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";

import axios from "../../../../axios/axios.config";
import Loading from "../../../../components/Loading";
import FlexWrapper from "../../../../components/FlexWrapper";
import PuertoRicoFlagImg from "../../../../assets/puerto-rico.png";

const SpecialOrders = () => {
  const [orderData, setOrderData] = useState([]);
  const [orderClasses, setOrderClasses] = useState([]); //will be used for filtering
  const [classFilter, setClassFilter] = useState("all"); //filters order
  const [loading, setLoading] = useState("Loading Special Orders...");

  const {
    currentNetsuiteLocationIds,
    currentSubsidiary,
    currentDepartment,
    username,
  } = useSelector((state) => state.user);
  const { openAlert } = useGeneralAlert();
  const navigate = useNavigate();

  const filteredOrders =
    classFilter !== "all"
      ? orderData.filter((order) => order["Items Class"] === classFilter)
      : orderData;

  useEffect(() => {
    getSpecialOrders();
  }, []);

  const getSpecialOrders = async () => {
    try {
      const nsResponse = await axios.get(
        `netsuite/order-processing/get/special-orders?locations=${JSON.stringify(
          currentNetsuiteLocationIds
        )}&subsidiary=${currentSubsidiary}`
      );
      const classStorage = new Set();

      for (const order of nsResponse.data) {
        classStorage.add(order["Items Class"]);
      }

      setOrderClasses(Array.from(classStorage.keys()));
      setOrderData(nsResponse.data);
    } catch (error) {
      openAlert({
        type: "error",
        message: `Could Not Get Orders: ${
          error.response?.data?.msg || error.message
        }`,
        duration: 8000,
      });
    } finally {
      setLoading("");
    }
  };

  if (Boolean(loading)) {
    return <Loading message={loading} />;
  }

  return (
    <Container maxWidth="lg" sx={{ pb: 1 }}>
      <Typography variant="h4" textAlign="center" gutterBottom>
        Special Orders
      </Typography>

      {filteredOrders.length ? (
        <>
          <FlexWrapper alignItems="baseline" justifyContent="space-between">
            <FlexWrapper gap={1} alignItems="center">
              <FormControl sx={{ width: "200px" }} size="small">
                <InputLabel id="class-select-label">Filter By Class</InputLabel>
                <Select
                  labelId="class-select-label"
                  id="class-select"
                  value={classFilter}
                  label="Filter By Class"
                  onChange={(event) => setClassFilter(event.target.value)}
                >
                  <MenuItem value="all">All</MenuItem>
                  {orderClasses?.length
                    ? orderClasses.map((order) => (
                        <MenuItem key={order} value={order}>
                          {order}
                        </MenuItem>
                      ))
                    : null}
                </Select>
              </FormControl>
            </FlexWrapper>
            <Typography variant="caption" textAlign="center" gutterBottom>
              <strong>Total Orders:</strong> {filteredOrders.length}
            </Typography>
          </FlexWrapper>
          <Stack sx={{ pt: 1 }} spacing={2}>
            {filteredOrders?.map((order) => (
              <Card key={order["SO #"]} style={{ borderRadius: 8 }}>
                <CardContent>
                  {order["Shipping State"] === "PR" ? (
                    <Tooltip
                      arrow
                      placement="top"
                      title="This order ships to Puerto Rico"
                    >
                      <img height="25px" width="25px" src={PuertoRicoFlagImg} />
                    </Tooltip>
                  ) : null}
                  <Typography
                    variant="h5"
                    gutterBottom
                    textAlign="center"
                    fontWeight="bold"
                  >
                    {order["SO #"]} - {order["Brand Packaging"]}
                  </Typography>
                  <Typography gutterBottom textAlign="center">
                    {order["Customer"]}
                  </Typography>
                  <Typography variant="body2" gutterBottom textAlign="center">
                    PO #: {order["PO #"]}
                  </Typography>

                  <Typography variant="body1" pt={1}>
                    <strong>Date: </strong> {order.Date}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Total Qty: </strong> {order["Total Qty"]} (Fill
                    Rate: {order["Projected Fill Rate %"]})
                  </Typography>
                  <Typography variant="body1">
                    <strong>Items: </strong> {order.Items}
                  </Typography>
                  <Typography variant="body1">
                    <strong>First Pick Bin: </strong> {order["First Pick Bin"]}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Shipping Method: </strong>{" "}
                    {order["Shipping Method"]}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Shipping State: </strong> {order["Shipping State"]}
                  </Typography>
                  {Boolean(order["Related Transfer Orders"]) ? (
                    order["Pick Locked By"] !== "" &&
                    order["Pick Locked By"] !== username ? (
                      <Typography variant="body1">
                        <strong>Related Transfer Orders: </strong>{" "}
                        {order["Related Transfer Orders"]}
                      </Typography>
                    ) : (
                      <>
                        <FlexWrapper gap={1}>
                          <Typography>
                            <strong>Related Transfer Orders: </strong>{" "}
                          </Typography>

                          {order["Related Transfer Orders"]
                            ?.split("|")
                            .map((toOrder) => {
                              return (
                                <Link
                                  key={toOrder}
                                  to={`/portal/${currentDepartment?.toLowerCase()}/pick-order?order=${toOrder}&redirect=/portal/${currentDepartment?.toLowerCase()}/pick-to-so?order=${toOrder}&original-page=/portal/${currentDepartment?.toLowerCase()}/special-orders`}
                                >
                                  <Typography> {toOrder}</Typography>
                                </Link>
                              );
                            })}
                        </FlexWrapper>
                      </>
                    )
                  ) : null}
                </CardContent>
                <CardActions>
                  <Button
                    disabled={
                      (order["Pick Locked By"] !== "" &&
                        order["Pick Locked By"] !== username) ||
                      !order["First Pick Bin"]
                    }
                    variant="contained"
                    onClick={() =>
                      navigate(
                        `/portal/${currentDepartment?.toLowerCase()}/pick-order?order=${
                          order["SO #"]
                        }&redirect=/portal/${currentDepartment?.toLowerCase()}/special-orders`
                      )
                    }
                  >
                    Pick Order
                  </Button>
                </CardActions>
                <Typography
                  variant="body2"
                  color="error"
                  textAlign="center"
                  pb={1}
                  textTransform="capitalize"
                >
                  {order["Pick Locked By"] !== "" &&
                  order["Pick Locked By"] !== username
                    ? `Order already being picked by ${order["Pick Locked By"]}`
                    : ""}
                </Typography>
                <Typography
                  variant="body2"
                  color="error"
                  textAlign="center"
                  pb={1}
                  textTransform="capitalize"
                >
                  {!order["First Pick Bin"] && order["Related Transfer Orders"]
                    ? "Transfer Order must be picked First"
                    : ""}
                </Typography>
              </Card>
            ))}
          </Stack>
        </>
      ) : (
        <Typography variant="h6" textAlign="center" py={2}>
          No Special Orders To Display
        </Typography>
      )}
    </Container>
  );
};
export default SpecialOrders;
